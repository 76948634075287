import { Link } from "gatsby"
import React from "react"
import Logo from "../assets/logo/logo.svg"
import { Seo } from "../components/Seo/Seo"

export default function NotFound() {
  return (
    <>
      <Seo
        index={false}
        title="Page Not Found | Sudoku Academy"
        lang={"en"}
        langUrls={[]}
        description="Sorry, we couldn’t find the page you’re looking for."
      />

      <div className="flex flex-col min-h-screen pt-16 pb-12 bg-white">
        <main className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex justify-center flex-shrink-0">
            <Link to="/" className="inline-flex">
              <span className="sr-only">Sudoku Academy</span>
              <img
                className="w-auto h-10"
                src={Logo}
                alt="Logo of Sudoku Academy"
                title="Logo of Sudoku Academy"
              />
            </Link>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold tracking-wide text-blue-600 uppercase">
                404 Error
              </p>
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-slate-900 sm:text-5xl">
                Page not found.
              </h1>
              <p className="mt-2 text-base text-slate-500">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6">
                <Link
                  to="/"
                  className="text-base font-medium text-blue-600 hover:text-blue-500"
                >
                  Go back home
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
